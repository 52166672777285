@font-face {
  font-family: 'Source San Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/SourceSansPro-Regular.eot'); /* IE9 Compat Modes */
  src: local('Source San Pro'), local('Source San Pro'),
       url('./fonts/SourceSansPro-Regular.otf.woff2') format('woff2'),
       url('./fonts/SourceSansPro-Regular.otf.woff') format('woff'), 
       url('./fonts/SourceSansPro-Regular.ttf') format('truetype')
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source San Pro';
}

.form-control {
	display: block;
	width: 90%;
	height: auto;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}


.bevbar {
  background-image: url(balken_rechts.jpg), url(balken_links.jpg) !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat, repeat;
  background-position: right top;
  height: 50px;
}

.logo {
	max-width: 190px;
	max-height: 75px;
}

/* .btn-link, .overallbuttons * {
	color: red !important;
} */

:root{
  --bev-color-red: #E6320F;
  --bev-color-black: #000;
  --bev-color-gray: #ccc;
  --bev-color-white: #fff;

  --bev-max-page-width: 1280px;
  --bev-max-page-query-width: calc( 1280px + 19px );

  --bev-md-break-point: 960px;
  --bev-sm-break-point: 600px;
  --bev-xs-break-point: 0px;
}

body{
  font-family: 'Source San Pro', sans-serif !important;
}

.leaflet-marker-icon.leaflet-div-icon{
  background: none;
  border:none;
}

.set {
  font-weight: bold;
  font-size: 10px;
  color:#E6320F;
}

.notset {
  font-size: 10px;
  color: #ccc;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.drawer{
  background-color: #E6320F;
}

.api-error-banner{
  width: 100%;
    color: white;
    font-size: 1.5em;
    text-align: center;
    background-color: #E6320F;
    margin: 15px 0;
    padding: 10px;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)
}

.maintenance{
  color: #fff;
  font-weight: bolder;
  background-color: #17a2b8; /* #2196f3;*/
  display: flex;
  justify-content: center;
  padding: 1.4em;
  text-align: center;
  font-size: 1.2em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)

}


.breadcrumb{
  background-color: #ccc;
}

.breadcrumb > .breadcrumb-item {
  color: #000;
}

.breadcrumb > .breadcrumb-item > a {
  color: #000;
}

.breadcrumb > .breadcrumb-item > a:hover {
  color: #000;
  text-decoration: underline;
}

.breadcrumb > .breadcrumb-item.active{
  color: #E6320F;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.header-wrapper{
  background-color: #fff !important;
  padding-right: 0 !important;
}

.header-logo{
  padding: 0 10px; 
  background-color: #fff;
}

.header-logo > .logo {
  margin: 10px 0;
}

.header-menu{
  padding: 0 10px; 
  background-color: #E6320F;
  justify-content: flex-end;
}

.header-menu > .hidden-menu-btn {
  display: none;
}

#header-menu-list {
  padding: 0;
}

#header-menu-list > .menu-item{
  color: #fff;
  display: inline-flex;
  align-items: center;
  height: 34px;
}

#header-menu-list > .menu-item:hover {
  text-decoration: underline;
}

#header-menu-list > .menu-item:active {
  text-decoration: underline;
}

#header-menu-list > .menu-item:focus {
  text-decoration: underline;
}

#header-menu-list > .menu-item.active {
  text-decoration: underline;
}
#header-menu-list > .menu-item.last {
  text-decoration: none;
}
#header-menu-list > .menu-item.last:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-menu-list > .menu-item > .menu-icon {
  color: #fff;
  margin-right: 0;
  min-width: 24px;
}

#header-menu-list > .menu-item > .menu-label {
  padding-left: 5px;
  padding-right: 5px;
}

#header-menu-list > .menu-item.last > .menu-label {
  text-decoration: none;
  background-color: transparent;
}
#header-menu-list > .menu-item.last:hover > .menu-label {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-menu-list > .menu-item > .menu-label > span {
  color: #fff;
}

#header-menu-list .dropdown{
  position: absolute;
  background-color: #fff;
  z-index: 2;
  right: 0;
  box-shadow: 0px 1px 1px 0px black;
}

.menu-item > .menu-icon {
  color: black;
  margin-right: 0;
  min-width: 24px;
}

.menu-item > .menu-label {
  color: black;
  padding-left: 5px;
  padding-right: 5px;
}

.header-logo{
  height: 85px;
  position: relative;
}

#header-logo-menu-list {
  position: absolute;
  right: 10px;
  bottom: 0;
  padding: 0;
}

#header-logo-menu-list > .menu-item {
  color: #000;
  display: inline-flex;
  align-items: center;
  padding: 2px 5px;
  min-height: 0;
}

#header-logo-menu-list > .menu-item.last {
  text-decoration: none;
  background-color: transparent;
}

#header-logo-menu-list > .menu-item.last:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-logo-menu-list > .menu-item.active {
  color: #E6320F;
  text-decoration: underline;
}

#header-logo-menu-list > .menu-item:hover {
  text-decoration: underline;
}

#header-logo-menu-list .menu-item > .menu-icon {
  color: #000;
  margin-right: 0;
  font-size: 0.8em;
  min-width: 15px ;
} 

#header-logo-menu-list .menu-item > .menu-label {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
}
#header-logo-menu-list > .menu-item.last > .menu-label {
  text-decoration: none;
  background-color: transparent;
}

#header-logo-menu-list > .menu-item.last:hover > .menu-label {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-logo-menu-list > .menu-item > .menu-label > span {
  color: #000;
  font-size: 0.8em;
}

#header-logo-menu-list .dropdown{
  position: absolute;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px 1px 1px 0px black;
}

.header-title{
  color: #000;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  width:100%;
  padding: 0 10px;
  text-align: right;
  font-size: 2.5rem;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.main-container{
  width: 100%;
  padding: 0 10px;
}

#header-drawer{
  background-color: #E6320F;
  height: 100%;
}

#header-drawer > .menu-item{
  color: #fff;
  height: 34px;
}

#header-drawer > .menu-item:hover {
  text-decoration: underline;
}

#header-drawer > .menu-item:active {
  text-decoration: underline;
}

#header-drawer > .menu-item:focus {
  text-decoration: underline;
}

#header-drawer > .menu-item.active {
  text-decoration: underline;
}
#header-drawer > .menu-item.last {
  text-decoration: none;
  background-color: transparent;
}
#header-drawer > .menu-item.last:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-drawer > .menu-item > .menu-icon {
  color: #fff;
  margin-right: 0;
  min-width: 24px;
}

#header-drawer > .menu-item > .menu-label {
  padding-left: 5px;
  padding-right: 5px;
}

#header-drawer > .menu-item.last > .menu-label {
  text-decoration: none;
  background-color: transparent;
}

#header-drawer > .menu-item.last:hover > .menu-label {
  text-decoration: none;
  background-color: transparent;
  cursor: auto;
}

#header-drawer > .menu-item > .menu-label > span {
  color: #fff;
}


@media all and (max-width: 600px){
  .header-menu {
    justify-content: space-between;
  }
  .header-menu .header-title {
    font-size: 1.75em;
    color: white;
    width: 100%;
    text-align: left;
    position: relative;
    top: 0;
    padding: 0;
    margin:  0;
  }
}

@media all and (max-width: 910px) {
  
  .header-menu > .menu-list {
    display: none;
  }
  .header-menu > .hidden-menu-btn {
    display: block;
    color: white;
  }
}

@media all and (min-width: 1043px ) {
  .header-logo{
    padding: 0 calc( ( 100vw - 1043px ) / 2 ); 
  }
  
  .header-menu{
    padding: 0 calc( ( 100vw - 1043px ) / 2 ); 
  }

  .header-title{
    padding: 0 calc( ( 100vw - 1043px ) / 2 ); 
  }

  #header-logo-menu-list {
    position: absolute;
    right: calc( ( 100vw - 1043px ) / 2 ); ;
    bottom: 0;
  }

  .main-container{
    padding: 0 calc( ( 100vw - 1043px ) / 2 ); 
  }
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* OpenLayers */
.switch-layer{
  top: 10px;
  right: 10px;

}


h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.ol-scale-line.ol-unselectable{
  background-color: rgba(255,255,255,0.5);
}

.ol-scale-line .ol-scale-line-inner {
  color: rgba(230, 50, 15, 1);
  border-color: rgba(230, 50, 15, 1);
}

.ol-zoomslider.ol-control button{
  background-color: rgba(230, 50, 15, 1);
}

.ol-zoom.ol-control button{
  background-color: rgba(230, 50, 15, 1);
  color:white;
}
